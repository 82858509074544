@import './_colors';

@mixin comnBorderRadius {
    border-radius: .25rem;
    overflow: hidden;
}

@mixin flexContent {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin flexColumnContent {
    @include flexContent;
    flex-direction: column;
}
@mixin flexRowContent {
    @include flexContent;
    flex-direction: row;
}
@mixin flexBetweenContent {
    @include flexRowContent;
    justify-content: space-between;
}

@mixin gridContent {
    display: grid;
    justify-content: center;
    align-items: center;
}

@mixin submitButton {
    @include comnBorderRadius;
}

@mixin transition {
    -webkit-transition: transform .1s;
    -webkit-transition: -webkit-transform .1s;
    transition: all .1s cubic-bezier(0.0, 0.0, 0.58, 1.0);
}

/* 이미지 */
@mixin imgArea {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
@mixin roundedImgArea {
    border-radius: 50%;
    overflow: hidden;
    @include imgArea;
}

/* 말줄임 */
@mixin lineClamp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@mixin lineClamp2 {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
@mixin lineClamp3 {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* 리스트스타일공통 */
@mixin arrayListElement {
    input[type=checkbox] {
        margin-right: 1.25rem;
    }
    label, span, h1, h2 {
        display: block;
        width: 100%;
        @include lineClamp;   
    }
    .center {
        text-align: center;
    }
    svg,
    label {
        font-size: 1rem;
        color: $white;
    }
    span {
        font-size: .875rem;
        color: $gray-25;
    }
    h2 {
        font-size: .875rem;
        color: $primary;
    }
    .stretch {
        flex-grow: 1;
        justify-content: flex-start !important;
    }
    hr {
        display: block;
        width: 1px !important;
        padding: 0;
        margin: 0 1.25rem;
        background: $gray-1;
        border: none;
    }
    /* START 입력필드 */
    .focusField,
    .textField {
        @include flexRowContent;
        gap: 4px;
        justify-content: flex-start;
        width: 100%;
        height: auto !important;
        font-size: .875rem;
        color: $gray-25;
        input[type=text],
        input[type=date] {
            width: 100%;
            max-width: 11rem;
            padding: .25rem 1rem;
            @include comnBorderRadius;
            text-align: center;
            color: $gray-25;
        }
        input[type=date] {
            color-scheme: dark; 
        }
    }
    label.focusField, label.textField {
        input[type=text] {
            font-size: 1rem;
            color: $white;
        }
    }
    span.focusField, span.textField {
        input[type=text] {
            font-size: .875rem;
            color: $gray-25;
        }
    }
    .textField {
        input[type=text],
        input[type=date] {
            border: 1px solid $gray-1;
            background-color: $gray-1;
        }
    }
    .focusField {
        input[type=text],
        input[type=date] {
            border: 1px solid $gray-0;
            background-color: $gray-0;
            &:focus {
                border: 1px solid $gray-1;
                background-color: $gray-1;
                font-size: .875rem;
                color: $gray-25;
            }
        }
    }
    /* END 입력필드 */
}
@mixin comnArrayList {
    @include flexColumnContent;
    width: 100%;
    li {
        @include flexRowContent;
        justify-content: flex-start;
        width: 100%;
        background-color: $gray-0;
        padding: 1rem 1.25rem;
        @include arrayListElement;
        @include comnBorderRadius;
        > div {
            @include flexRowContent;
            > div {
                @include flexColumnContent;
                width: 100%;
                > * {
                    width: 100%;
                }
            }
            * {
                line-height: 150%;
            }
            &:not(.stretch) {
                text-align: center;
            }
        }
    }
}
@mixin comnArrayToggleList {
    @include flexColumnContent;
    width: 100%;
    li {
        @include flexColumnContent;
        justify-content: flex-start;
        width: 100%;
        background-color: $gray-0;
        @include arrayListElement;
        @include comnBorderRadius;
        > * {
            width: 100%;
        }
        > div {
            @include flexRowContent;
            justify-content: flex-start;
            width: 100%;
            padding: 1rem 1.25rem;
            > div {
                @include flexRowContent;
                width: 100%;
                > div {
                    @include flexColumnContent;
                    > * {
                        width: 100%;
                        
                    }
                    &:not(.stretch) {
                        text-align: center;
                    }
                }
                * {
                    line-height: 150%;
                }
            }
        }
        article {
        }
    }
}

/* 버튼공통클래스 */
@mixin comnButton {
    @include flexRowContent;
    gap: .5rem;
    box-sizing: border-box;
    height: 3rem;
    padding: .75rem;
    background: none;
    border: 1px solid $white;
    @include comnBorderRadius;
    white-space: nowrap;
    > * { color: $white; }
    svg {
        font-size: 1rem;
    }
    span {
        font-weight: 500;
        font-size: .875rem;
    }
}
@mixin btnError {
    @include comnButton;
    border: 1px solid $error;
    > * { color:$error; }
}