@import '../config/variables';

.searchArea {
    @include flexRowContent;
    gap: .625rem;
    justify-content: flex-start;
    .dateField {
        @include flexRowContent;
        gap: .25rem;
        background-color: $gray-0;
        padding: .75rem 1.25rem;
        @include comnBorderRadius;
        span {
            font-size: 1rem;
            color: $gray-2;
        }
        input[type=date] {
            width: 8.925rem;
            padding: .5rem 1.25rem;
            border: none;
            @include comnBorderRadius;
            background-color: transparent;
            text-align: center;
            color: $gray-25;
            color-scheme: dark; 
        }
    }
}