@import '../config/variables';

/* 업체검색 */
.searchArea {
    @include flexRowContent;
    gap: .625rem;
    justify-content: flex-start;
    > * {
        height: 3rem;
        box-sizing: border-box;
    }
    .searchField {
        @include flexRowContent;
        gap: .25rem;
        background-color: $white;
        padding: .75rem 1.25rem;
        @include comnBorderRadius;
        > * {
            border: none;
            background-color: transparent;
        }
        input[type=text] {
            width: 40rem;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-1;
            &::placeholder {
                color: $gray-25;
            }
        }
        button {
            font-size: 1.5rem;
            color: $gray-25;
        }
    }
}

/* 업체리스트 */
.listArea {
    ul {
        @include flexColumnContent;
        gap: .75rem;
        li {
            @include flexRowContent;
            gap: 0;
            width: 100%;
            padding: 1rem 1.25rem;
            @include comnBorderRadius;
            background-color: $gray-0;
            > a,
            > div {
                @include flexColumnContent;
                gap: .12rem;
                align-items: flex-start;
                > * {
                    height: 1.5rem;
                    line-height: 150%;
                }
                * {
                    width: 100%;
                    font-weight: 500;
                    @include lineClamp;
                }
                label {
                    font-size: 1rem;
                    color: $white;
                }
                span {
                    font-size: .875rem;
                    color: $gray-25;
                }
                em {
                    font-size: .875rem;
                    color: $white;
                }
                h5 {
                    text-align: left;
                    font-size: 1rem;
                    color: $error;
                }
                button {
                    @include flexRowContent;
                    gap: .12rem;
                    padding: 0.375rem 0.5rem;
                    background-color: transparent;
                    border: 1px solid $error;
                    white-space: nowrap;
                    @include comnBorderRadius;
                    > * {
                        color: $error;
                    }
                    span {
                        font-size: .875rem;
                        overflow: visible;
                    }
                }
            }
            hr {
                display: block;
                width: 1px;
                height: 2.5rem;
                padding: 0;
                margin: 0 1.25rem;
                background: $gray-1;
                border: none;
            }
            .stretch {
                flex-grow: 1;
            }
            .imgArea {
                width: 2.75rem;
                height: 2.75rem;
                margin-right: 1.25rem;
                @include roundedImgArea;
            }
        }
    }
}