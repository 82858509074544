@import '../config/variables';

section {
    @include flexContent;
    width: 100%;
    height: 100%;
    background-image: url('../../images/bg/login-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .loginArea {
        @include flexColumnContent;
        gap: 3rem;
        box-sizing: border-box;
        width: 38.875rem;
        padding: 5.38rem;
        border-radius: 1.25rem;
        background: $white;
        > * {
            width: 100%;
        }
        h1 {
            font-weight: 700;
            font-size: 2rem;
            color: $gray-0;
        }
        .inputArea {
            @include flexColumnContent;
            gap: 1.5rem;
            > div {
                @include flexColumnContent;
                align-items: flex-start;
                gap: .75rem;
                width: 100%;
                label {
                    font-weight: 400;
                    font-size: .875rem;
                    color: $gray-1;
                }
                input {
                    box-sizing: border-box;
                    width: 100%;
                    border: 1px solid $gray-4;
                    @include comnBorderRadius;
                    background: $white;
                    padding: 1.25rem;
                    font-weight: 500;
                    font-size: 1rem;
                    color: $gray-1;
                }
            }
            .inputField {
                position: relative;
                span {
                    position: absolute;
                    bottom: -1.125rem;
                    font-weight: 500;
                    font-size: .75rem;
                    color: $error;
                }
            }
        }
    }
}
/* validation */
.invalid {
    input {
        border: 1px solid $error !important;
    }
}