@import '../config/variables';

.ReactModal__Overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, .7) !important;
    z-index: 10 !important;
    .ReactModal__Content {
        inset: initial !important;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        justify-content: center !important;
        width: 360px;
        /*height: 100% !important;*/
        max-height: 80vh !important;
        background-color: $white !important;
        border-radius: 1.25rem !important;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .25) !important;
        overflow: hidden !important;
        z-index: 150 !important;
    }
}

/* customeStyle */
.ReactModal__Content {
    padding: 2.5rem !important;
    .popupContainer {
        @include flexColumnContent;
        justify-content: flex-start !important;
        gap: .5rem;
        width: 100%;
        height: 100%;
        > * {
            width: 100%;
        }
        .popupHeader {
            @include flexColumnContent;
            justify-content: flex-start;
            // min-height: 3.375rem;
            > * {
                width: 100%;
            }
            h1 {
                line-height: 168.75%;
                font-weight: 700;
                font-size: 1.5rem;
                color: $gray-0;
            }
        }
        .popupContainer {
            @include flexColumnContent;
            justify-content: flex-start;
            flex-grow: 1;
            overflow-y: auto;
            &.fullHeight {
                overflow-y: hidden;
            }
        }
        .popupFooter {
            margin-top: 2.5rem;
            .btnArea {
                @include flexRowContent;
                gap: 1.25rem;
                flex-grow: 1;
                width: 100%;
                button {
                    width: 100%;
                    height: 4rem;
                    padding: 1.19rem;
                    box-sizing: border-box; 
                    @include comnBorderRadius;
                    span {
                        font-weight: 600;
                        font-size: 1.125rem;
                        color: $gray-0
                    }
                }
                .closeButton {
                    @include flexRowContent;
                    gap: .75rem;
                    min-width: 4.8rem;
                    max-width: 4.8rem;
                    padding: 1.19rem .5rem;
                    border: none;
                    background: transparent;
                    span {
                        color: $gray-25;
                    }
                    svg {
                        font-size: 1.25rem;
                    }
                }
                .submitButton {
                    background-color: $primary;
                    border: 1px solid $primary;
                    &:disabled {
                        border: 1px solid $gray-4;
                        background-color: $gray-4;
                        span {
                            color: $white;
                        }
                    }
                }
                .secondaryButton {
                    background-color: $white;
                    border: 1px solid $gray-4;
                }
            }
        }
    }
}

/* 팝업입력폼공통 */
.popupFormArea {
    @include flexColumnContent;
    gap: 1.25rem;
    > * {
        width: 100%;
    }
    > div {
        @include flexColumnContent;
        gap: .75rem;
        > * {
            width: 100%;
        }
    }
    label {
        font-weight: 500;
        font-size: .875rem;
        color: $gray-1;
    }
    .txtRight {
        text-align: right;
    }
    .textArea, .textarea {
        width: 100%;
        padding: 1.25rem;
        box-sizing: border-box;
        background-color: $white;
        border: 1px solid $gray-4;
        @include comnBorderRadius;
    }
    /* input */
    .textArea {
        @include flexRowContent;
        gap: .25rem;
        height: 4rem;
        input[type=text] {
            width: 100%;
            border: none;
            background-color: transparent;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-0;
            &::placeholder {
                color: $gray-4;
            }       
        }
        span {
            font-weight: 500;
            font-size: 1rem;
            color: $gray-2;
        }
    }
    /* textare */
    .textarea {
        textarea {
            width: 100%;
            height: 5.125rem;
            border: none;
            background-color: transparent;
            line-height: 150%;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-0;
            &::placeholder {
                color: $gray-4;
            }
        }
    }
    /* inputRadio */
    .radioArea {
        @include flexRowContent;
        gap: .75rem;
        > * {
            @include flexRowContent;
            gap: 1rem;
            padding: 1.25rem;
            background-color: $gray-6;
            label {
                font-weight: 500;
                font-size: 1rem;
                color: $gray-1;
            }

        }
        justify-content: flex-start;
    }
    /* inputFile */
    .fileArea {
        position: relative;
        label {
            @include flexRowContent;
            gap: .5rem;
            width: 100%;
            height: 4rem;
            padding: 1.25rem;
            box-sizing: border-box;
            border: 1px dashed $gray-2;
            @include comnBorderRadius;
            background-color: $white;
            font-weight: 600;
            font-size: 1.125rem;
            color: $gray-2;
            cursor: pointer;
            svg {
                font-size: 1rem;
            }
        }
        input[type="file"] {
            position: absolute;
            width: 0;
            height: 0;
            padding: 0;
            overflow: hidden;
            border: 0;
        }
    }
    /* 이미지미리보기 */
    .imgPreview {
        @include flexRowContent;
        justify-content: flex-start;
        gap: 1.25rem;
        height: 4rem;
        padding: 1.25rem 1rem;
        box-sizing: border-box;
        border: 1px solid $gray-4;
        @include comnBorderRadius;
        button {
            border: none;
            background-color: transparent;
            font-size: 1rem;
            color: $gray-1;
        }
        .previewImage {
            @include flexRowContent;
            width: 3rem;
            height: 3rem;
            background-color: $gray-6;
            @include comnBorderRadius;
            overflow: hidden;
            img {
                height: 2rem;
                object-fit: cover;
            }
        }
        label {
            font-weight: 500;
            font-size: .875rem;
            color: $gray-25;
        }
    }
}