@import '../config/variables';

/* 당일집계 */
.countArea {
    @include flexRowContent;
    gap: .5rem;
    > div {
        @include flexBetweenContent;
        gap: .5rem;
        box-sizing: content-box;
        min-width: 12.625rem;
        height: 2.5rem;
        padding: 1rem 1.25rem;
        background: $gray-0;
        @include comnBorderRadius;
        > * {
            font-weight: 500;
            white-space: nowrap;
        }
        h1 {
            font-size: .875rem;
            color: $gray-2;
        }
        h2, h3 {
            font-size: 1rem;
        }
        h2 {
            color: $white;
        }
        h3, a {
            color: $primary;
        }
        a {
            font-size: 1rem;
        }
        span {
            @include flexRowContent;
            gap: .5rem;
        }
    }
    .payment {
        width: 100%;
    }
}

/* 당일예약목록 */
.reservContainer {
    @include flexColumnContent;
    width: 100%;
    height: 100%;
    > * {
        width: 100%;
    }
    .title {
        @include flexRowContent;
        gap: .25rem;
        justify-content: flex-start;
        margin-top: 2.5rem;
        margin-bottom: .75rem;
        color: $white;
        span {
            font-size: 1rem;
        }
        svg {
            font-size: 1.125rem;
        }
    }
    .listArea {
        ul {
            @include comnArrayList;
            gap: .75rem;
            li {
                hr {
                    height: 1.25rem;
                }
            }
        }
    }
}