@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable.css");
@import './asset/scss/config/_variables.scss';

* {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-display: swap;
  box-sizing: border-box;
  outline: 0 !important;
  outline: none !important;
  scroll-behavior: smooth !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  overscroll-behavior: auto;
  -ms-content-zooming: none;
  touch-action: auto;         
  -webkit-touch-action: auto;
  -ms-touch-action: auto;
  -webkit-font-smoothing: auto; 
  /* transition */
  /*
  transition: all 200ms;
  -webkit-transition: transform 200ms;
  -webkit-transition: -webkit-transform 200ms;
  */
  /* scroll */
  // scroll-snap-type: both proximity;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
}

html,
body,
#root {
  vertical-align: baseline;
  width: 100%;
  height: 100%;
}
body {
  position: relative;
  display: block;
  line-height: 1;
  overflow: hidden;
  #root {
    > * {
      padding: 0;
      margin: 0;
    }
  }
}

.container {
  @include flexRowContent;
  .containerArea {
    @include flexColumnContent;
    gap: 2rem;
    flex-grow: 1;
    box-sizing: border-box;
    position: relative;
    width: calc(100% - 20%);
    height: 100%;
    padding: 3.5rem;
    background: $gray-1;
    > * {
      width: 100%;
    }
    .fixedPanel {
    }
    .flickPanel {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}

a {
  text-decoration: none;
}

h1, h2, h3, h5,
p, fieldset,
pre {
  padding: 0;
  margin: 0;
}

em {
  font-style: normal;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button,
a {
  cursor: pointer !important;
  * {
    cursor: pointer !important;
  }
}

pre {
  white-space: break-spaces;
  white-space: pre-wrap !important;
}