$white: #FFF;
$black: #000;
$gray-0: #191A1A;
$gray-1: #373737;
$gray-15: #232424;
$gray-2: #737373;
$gray-25: #A1A1A1;
$gray-4: #C9C9C9;
$gray-45: rgba(201, 201, 201, .70);
$gray-5: #989898;
$gray-6: #F3F3F3;
$gray-7: rgba(255, 255, 255, .70);
$primary: #FFD400;
$primary-outline: #D89B00;
$point-blue: #0097EC;
$point-purple: #D354FF;
$error: #FF5454;
$correct: #00BC29;