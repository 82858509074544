@import '../config/variables';

.submitPopupArea {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    @include flexRowContent;
    > div {
        display: inline-block;
        text-align: center;
        > div {
            @include flexRowContent;
            gap: .5rem;
            width: auto;
            padding: .75rem 1.25rem !important;
            @include comnBorderRadius;
            background-color: $gray-0;
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            svg {
                font-size: 1.5rem;
                &.submit {
                    color: $correct;
                }
                &.error {
                    color: $error;
                }
                &.notice {
                    color: $primary;
                }
            }
            span {
                display: block;
                box-sizing: border-box;
                font-weight: 500;
                font-size: 1rem;
                color: $gray-25;
            }
        }
    }
}