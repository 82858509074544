@import '../config/variables';

/* START 제품정보 */
.infoContainer {
    @include flexColumnContent;
    align-items: flex-start;
    gap: 2rem;
    .imgNoneArea, .imgArea {
        width: 8.0625rem;
        height: 8.0625rem;
        margin-top: .25rem;
        background-color: $white;
        padding: 1rem;
        @include comnBorderRadius;
        overflow: hidden;
    }
    .imgNoneArea {
        @include flexRowContent;
        span {
            font-weight: 500;
            font-size: .75rem;
            color: $gray-2;
        }
    }
    .infoArea {
        @include flexColumnContent;
        align-items: flex-start;
        gap: 1rem;
        > div {
            @include flexColumnContent;
            align-items: flex-start;
            > * {
                line-height: 150%;
                font-weight: 500;
                font-size: 1rem;
            }
            span {
                color: $gray-25;
            }
            label {
                color: $white;
                &.vehicleList {
                    position: relative;
                    padding-left: .75rem;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        display: block;
                        width: .25rem;
                        height: .25rem;
                        border-radius: 50%;
                        background-color: $white;
                    }
                }
            }
        }
    }
}
/* END 제품정보 */
/* START 버튼 */
.btnArea {
    @include flexRowContent;
    justify-content: flex-start;
    gap: .5rem;
    a,
    button {
        @include flexRowContent;
        gap: .5rem;
        box-sizing: content-box;
        height: 2.25rem;
        padding: .38rem .75rem;
        background-color: transparent;
        border: 1px solid $white;
        @include comnBorderRadius;
        color: $white;
        span {
            font-size: .875rem;
        }
        &:disabled {
            color: $gray-2;
            border: 1px solid $gray-2;
        }
    }
}
/* END 버튼 */