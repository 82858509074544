@import '../config/variables';

/* 업체검색 */
.searchArea {
    @include flexRowContent;
    gap: .625rem;
    justify-content: flex-start;
    > * {
        height: 3rem;
        box-sizing: border-box;
    }
    .searchField {
        @include flexRowContent;
        gap: .25rem;
        background-color: $white;
        padding: .75rem 1.25rem;
        @include comnBorderRadius;
        > * {
            border: none;
            background-color: transparent;
        }
        input[type=text] {
            width: 40rem;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-1;
            &::placeholder {
                color: $gray-25;
            }
        }
        button {
            font-size: 1.5rem;
            color: $gray-25;
        }
    }
    .dateField {
        @include flexRowContent;
        gap: .25rem;
        background-color: $gray-0;
        padding: .75rem 1.25rem;
        @include comnBorderRadius;
        span {
            font-size: 1rem;
            color: $gray-2;
        }
        input[type=date] {
            width: 8.925rem;
            padding: .5rem 1.25rem;
            border: none;
            @include comnBorderRadius;
            background-color: transparent;
            text-align: center;
            color: $gray-25;
            color-scheme: dark; 
        }
    }
}

/* 회원리스트 */
.listArea {
    ul {
        @include comnArrayList;
        gap: .75rem;
        li {
            .imgArea {
                width: 2.75rem;
                height: 2.75rem;
                margin-right: 1.25rem;
                @include roundedImgArea;
                svg {
                    font-size: 2.75rem;
                }
            }
            h5 {
                min-width: 150px;
                text-align: left;
                font-size: 1rem;
                color: $error;
            }
            .regDate {
                @include flexRowContent;
                gap: .2rem;
                span {
                    width: 5.3125rem;
                }
            }
            hr {
                height: 1.25rem;
            }
        }
    }
}