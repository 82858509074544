@import '../config/variables';

.datasContainer {
    @include flexColumnContent;
    gap: 1.25rem;
    justify-content: flex-start;
    > * {
        width: 100%;
    }
}

/* START 제목 */
h1 {
    font-weight: 600;
    font-size: 1.125rem;
    color: $white;
}
.titleArea {   
    @include flexBetweenContent;
    > div {
        @include flexRowContent;
        gap: .75rem;
        span {
            position: relative;
            padding-left: .5rem;
            font-weight: 500;
            font-size: .75rem;
            color: $white;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                display: block;
                width: .25rem;
                height: .25rem;
                border-radius: 50%;
            }
            &:first-child {
                &::before {
                    background-color: $white;
                }
            }
            &.green {
                &::before {
                    background-color: $correct;
                }
            }
            &.yellow {
                &::before {
                    background-color: $primary;
                }
            }
        }
    }
}
/* END 제목 */

/* START 월별매출액통계 */
.reservStatsContainer {
    width: 100%;
    height: 35.125rem;
    padding: 1.25rem;
    @include comnBorderRadius;
    background-color: $gray-0;
    .lineChartArea {
        width: 100%;
        height: 28.5rem;
        margin-top: 2.5rem;
    }
}
/* END 월별매출액통계 */
/* START 이용자통계 */
.regDataContainer {
    @include flexRowContent;
    gap: 1.25rem;
    .lineChart,
    .totCnt > * {
        @include comnBorderRadius;
        background-color: $gray-0;
    }
    .lineChart {
        width: calc(100% - 33%);
        height: 25.125rem;
        padding: 1.25rem;
        .lineChartArea {
            width: 100%;
            height: 19.5rem;
            margin-top: 1.5rem;
        }
    }
    .totCnt {
        @include flexColumnContent;
        gap: 1.25rem;
        width: calc(100% - (33% * 2));
        > * {
            width: 100%;
            padding: 1.25rem 2.5rem;
        }
        > div {
            @include flexColumnContent;
            align-items: flex-start;
            gap: .12rem;
            height: 11.9375rem;
            > * {
                line-height: 150%;
                color: $white;
            }
            h1 {
                margin-bottom: .38rem;
                font-weight: 500;
                font-size: 1rem;
            }
            h2 {
                font-weight: 700;
                font-size: 2rem;
            }
            > div {
                @include flexRowContent;
                > * {
                    font-weight: 500;
                    font-size: .875rem;
                    &:last-child {
                        margin-left: .25rem;
                    }
                }
                label, svg {
                    margin-right: .25rem;
                }
                label {
                    color: $gray-4 !important;
                }
                &.up > * {
                    color: $correct;
                }
                &.down > * {
                    color: $point-blue;
                }
            }
        }
    } 
}
/* END 이용자통계 */
/* START 이용자차량등록순위 */
.memberVehicleContainer {
    @include flexRowContent;
    gap: 1.25rem;
    .pieChart,
    .modelRank {
        min-height: 25.125rem;
        @include comnBorderRadius;
        background-color: $gray-0;
        padding: 1.25rem;
    }
    .pieChart {
        width: calc(100% - (33% * 2));
        height: 100%;
        > div {
            @include flexColumnContent;
            gap: 1.5rem;
            width: 100%;
            height: (12.5rem + 6.5rem + 1.5rem);
            margin-top: 1.75rem;
            path {
                stroke: transparent;
            }
            text {
                font-weight: 700;
                font-size: .875rem;
            }
            .dataListArea {
                @include gridContent;
                grid-template-columns: 1fr 1fr;
                column-gap: 1rem;
                row-gap: .5rem;
                width: 100%;
                li {
                    @include flexBetweenContent;
                    gap: .25rem;
                    > * {
                        white-space: nowrap;
                        font-size: .875rem;
                    }
                    div {
                        width: 1rem;
                        min-width: 1rem;
                        height: 1rem;
                        min-height: 1rem;
                        @include comnBorderRadius;
                    }
                    span {
                        width: 100%;
                        font-weight: 500;
                        color: $gray-5;
                    }
                    label {
                        font-weight: 700;
                        color: $white;                        
                    }
                }
            }
        }
    }
    .modelRank {
        width: calc(100% - 33%);
        .rankArea {
            @include gridContent;
            grid-template-columns: 1fr 1fr;
            column-gap: 2.5rem;
            row-gap: 1.7rem;
            width: 100%;
            margin-top: 2.5rem;
            article {
                @include flexBetweenContent;
                gap: 1rem;
                width: 100%;
                h2 {
                    width: 1.1875rem;
                    font-weight: 500;
                    font-size: .875rem;
                    color: $primary;
                }
                .imgArea {
                    width: 2.5rem;
                    min-width: 2.5rem;
                    height: 2.5rem;
                    min-height: 2.5rem;
                    background-color: $white;
                    @include comnBorderRadius;
                    overflow: hidden;
                }
                > div {
                    @include flexBetweenContent;
                    width: 100%;
                    div {
                        @include flexColumnContent;
                        gap: .12rem;
                        &:first-child { align-items: flex-start; }
                        &:last-child { align-items: flex-end; }
                        > * {
                            font-weight: 500;
                            font-size: .75rem;
                        }
                        label {
                            color: $gray-25;
                        }
                        span {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
/* END 이용자차량등록순위 */
/* START 업체예약순위 */
.partnerReservContainer {
    @include flexRowContent;
    gap: 1.25rem;
    > div {
        width: 50%;
        min-height: 19.625rem;
        padding: 1.25rem;
        @include comnBorderRadius;
        background-color: $gray-0;
        .rankArea {
            @include flexColumnContent;
            justify-content: flex-start;
            gap: 2rem;
            > div {
                min-height: 12.5rem;
            }
            margin-top: 2rem;
            article {
                @include flexBetweenContent;
                gap: 1rem;
                width: 100%;
                h2 {
                    width: 1.1875rem;
                    font-weight: 500;
                    font-size: .875rem;
                    color: $primary;
                }
                .imgArea {
                    width: 2.75rem;
                    min-width: 2.75rem;
                    height: 2.75rem;
                    min-height: 2.75rem;
                    background-color: $white;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                > div {
                    @include flexBetweenContent;
                    gap: 1rem;
                    width: 100%;
                    div {
                        @include flexColumnContent;
                        gap: .12rem;
                        align-items: flex-start;
                        &:first-child { width: 11.937rem; }
                        &:last-child { width: 100%; }
                        > * {
                            width: 100%;
                            height: 1.25rem;
                            font-weight: 500;
                            font-size: .875rem;
                            white-space: nowrap;
                            @include lineClamp;
                        }
                        em {
                            color: $primary;
                        }
                        label {
                            color: $gray-25;
                        }
                        span {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
/* END 업체예약순위 */

/* START 차트툴팁 */
.tooltipArea {
    @include flexColumnContent;
    gap: .12rem;
    padding: .5rem .75rem;
    border-radius: .25rem;
    background-color: $gray-1;
    > div {
        @include flexRowContent;
        gap: .25rem;
        > * {
            font-size: .75rem;
        }   
        span {
            font-weight: 500;
            color: $gray-4;
        }
        label {
            font-weight: 700;
            color: $white;
        }
    }
}
/* END 차트툴팁 */