@import '../config/variables';

.titleArea {
    @include flexBetweenContent;
    padding-bottom: .75rem;
    border-bottom: 1px solid $gray-2;
    div {
        @include flexRowContent;
        gap: .25rem;
        h2 {
            font-weight: 600;
            font-size: 1.25rem;
            color: $white;    
        }
        em {
            font-weight: 500;
            font-size: .75rem;
            color: $primary;
        }

        svg {
            font-size: 1rem;
            color: $gray-2;
        }
        > span {
            @include flexRowContent;
            gap: .25rem;
        }
        span {
            font-weight: 400;
            font-size: .875rem;
            color: $gray-2;
        }
    }
}