@import './_colors';
@import './variables';

/* 높이100 */
.fullHeight {
  height: 100%;
}

input::-ms-clear,
input::-ms-reveal{
	display:none;
  width:0;
  height:0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration{
  -webkit-appearance: none !important;
  height: 1rem !important;
  width: 1rem !important;
  margin-left: .4em !important;
  border-radius: 50% !important;
  background-color: $gray-1 !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>") !important;
  background-size: .875rem .875rem !important;
  cursor: pointer;
}

/*  */
.show {
  display: inherit !important;
}
.hide {
  display: none !important;
}

/* 이미지공통컴포넌트 */
.imgArea {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.activeButtonArea {
    @include flexBetweenContent;
    gap: 1.25rem;
    width: 100%;
    > div {
        @include flexRowContent;
        gap: .5rem;
        justify-content: flex-start;
        &:first-child {
            overflow-x: auto;   
        }
        a,
        button {
          @include flexRowContent;
          gap: .5rem;
          box-sizing: border-box;
          height: 3rem;
          padding: .75rem;
          background: none;
          border: 1px solid $white;
          @include comnBorderRadius;
          white-space: nowrap;
          > * { color: $white; }
          svg {
              font-size: 1rem;
          }
          span {
              font-weight: 500;
              font-size: .875rem;
          }
          &.btnDelete {
              border: 1px solid $error;
              > * { color:$error; }
          }
          &:disabled {
            border: 1px solid $gray-2;
            > * { color: $gray-2; }
          }
          &.linkedButton {
            border: 1px solid $gray-0;
            background: $gray-0;
            color: $white;
          }
        }
    }
}

/* Start 데이터로드(infinity-observer) */
.inViewLoad {
    background-color: $gray-0;
    @include comnBorderRadius;
    margin-top: .75rem;
    padding: .5rem 1.25rem;
    text-align: center;
    font-weight: 500;
    font-size: .875rem;
    color: $gray-4;
}
.inViewData {
    background-color: $gray-0;
    @include comnBorderRadius;
    margin-top: .75rem;
    padding: .5rem 1.25rem;
    text-align: center;
    font-weight: 500;
    font-size: .875rem;
    color: $gray-4;
}
/* End 데이터로드(infinity-observer) */
/* Start 체크박스 */
[type=checkbox] {
    position: relative;
    width: 1.375rem;
    height: 1.375rem;
    color: $gray-1;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: 0;
    flex-grow: 0;
    border: 1px solid $gray-2;
    border-radius: 50%;
    background: transparent;
    /*transition: background .1s;*/
    cursor: pointer;
    &::before {
        content: "\f00c";
        font-family: "Font Awesome 6 Pro";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $gray-25;
        font-weight: 400;
        font-size: .75rem;
        background-color: transparent;
        background-size: contain;
    }
    &:checked {
        background-color: transparent;
        border: 1px solid $primary;
        &::before {
            color: $primary;
        }
        &::-ms-check {
            box-shadow: none;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
        }
    }
    &:disabled {
        background-color: $gray-25;
        opacity: 0.84;
        cursor: not-allowed;
    }
    &::-ms-check {
        content: "";
        color: transparent;
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        border: 0;
        background-color: transparent;
        background-size: contain;
        box-shadow: inset 0 0 0 1px #CCD3D8;
    }
}
.popupRadio {
  [type=checkbox] {
      border: 1px solid $gray-25;
      &::before {
          color: $gray-25;
      }
      &:checked {
          border: 1px solid $gray-0;
          &::before {
              color: $gray-0;
          }
      }
      &:disabled {
          background-color: $gray-4;
      }
  }
}
/* End 체크박스 */
/* Start 라디오버튼 */
.radio {
  white-space: nowrap;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: $white;
        border-radius: 100%;
        border: 1px solid $gray-25;
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;
        top: -0.2em;
        margin-right: 1em; 
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $primary;
          box-shadow: inset 0 0 0 .19rem $white;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $primary;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 .19rem $white;
          border-color: $gray-25;
          background: $gray-25;
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
/* End 라디오버튼 */
/* START input[type=search] */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration{
  -webkit-appearance: none;
  width: .75rem;
  height: .75rem; 
  background-image: url('../../images/icon/xmark-light.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
/* END input[type=search] */
/* START 예약상태컬러 */
.state1, .state1 > * { color: $primary !important; }//예약진행중
.state2, .state2 > * { color: $white !important; }//예약완료
.state3, .state3 > * { color: $point-purple !important; }//입고완료
.state4, .state4 > * { color: $correct !important; }//점검완료
.state5, .state5 > * { color: $point-blue !important; }//이력등록완료
.state6, .state6 > * { color: $error !important; }//수락지연취소
.state7, .state7 > * { color: $error !important; }//미입고취소
.state8, .state8 > * { color: $error !important; }//취소완료
/* END 예약상태컬러 */