@import '../config/variables';

/* 알림발송리스트 */
.flickPanel {
    height: 100%;
    overflow: hidden !important;
}
.notifyArea {
    @include flexRowContent;
    gap: 1.25rem;
    justify-content: flex-start;
    height: 100%;
    min-height: 648px;
    > div {
        width: 50%;
        max-width: calc(50% - (1.25rem * .5));
        height: 100%;
        &.notifyListArea {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            ul {
                @include flexColumnContent;
                gap: .75rem;
                justify-content: flex-start;
                align-items: center;
                li {
                    width: 100%;
                    background: $gray-0;
                    @include comnBorderRadius;
                    > * {
                        padding: 1.25rem;
                    }
                    > div {
                        @include flexColumnContent;
                        gap: .75rem;
                        justify-content: flex-start;
                        font-weight: 500;
                        font-size: .875rem;
                        width: 100%;
                        > div {
                            width: 100%;
                        }
                    }
                    .title {
                        @include flexBetweenContent;
                        gap: .75rem;
                        label {
                            flex: 1 1 auto;
                            color: $primary;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            cursor: pointer;
                        }
                        svg {
                            flex: 0 0 auto;
                            font-size: 1.25rem;
                            color: $gray-25;
                            min-width: 0;
                        }
                    }
                    .info {
                        @include flexRowContent;
                        justify-content: flex-start;
                        gap: 1rem;
                        color: $gray-25;
                        span {
                            position: relative;
                            height: 100%;
                            svg {
                                margin-right: .5rem;
                            }
                            &:not(:last-child) {
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    right: -.5rem;
                                    transform: translateY(-50%);
                                    display: block;
                                    width: 1px;
                                    height: 100%;
                                    background-color: $gray-1;
                                }
                            }
                        }
                    }
                    article {
                        border-top: 1px solid $gray-1;
                        fieldset {
                            border: none;
                            line-height: 150%;
                            font-size: .875rem;
                            color: $gray-4;
                            white-space: pre-wrap;
                        }
                    }
                }            
            }
        }
    }
}
/* 알람발송폼 */
.formArea {
    @include flexColumnContent;
    gap: 1.25rem;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    > div {
        @include flexColumnContent;
        gap: .75rem;
        align-items: flex-start;
        width: 100%;
        label {
            font-weight: 500;
            font-size: .875rem;
            color: $white;
        }
        .textField,
        .textareaField, 
        .radioArea > div {
            width: 100%;
            @include comnBorderRadius;
            background: $gray-0;
            padding: 1.25rem;
        }
        input[type=text], input[type=search], textarea {
            font-size: .875rem;
            &::placeholder {
                font-size: 1rem;
            }
        }
        .textField {
            input {
                width: 100%;
                background-color: transparent;
                border: none;
                font-weight: 500;
                color: $gray-4;
            }
        }
        .textareaField {
            textarea {
                width: 100%;
                height: 3.75rem;
                background-color: transparent;
                border: none;
                line-height: 150%;
                font-weight: 500;
                color: $gray-4;
            }
        }
        .radioArea {
            @include flexRowContent;
            gap: .75rem;
            > div {
                label {
                    position: relative;
                    top: 4px !important;
                    font-weight: 500;
                    font-size: 1rem;
                    color: $white;
                }
            }
        }
        .checkoutArea {
            position: relative;
            @include gridContent;
            grid-template-columns: 6.5rem 1fr;
            column-gap: .75rem;
            width: 100%;
        }
        .listArea {
            width: 100%;
            ul {
                @include flexRowContent;
                justify-content: flex-start;
                gap: .75rem;
                width: 100%;
                overflow-x: auto;
                li {
                    @include flexRowContent;
                    gap: .5rem;
                    @include comnBorderRadius;
                    padding: 1rem 1.25rem;
                    border: 1px solid $gray-2;
                    overflow: visible !important;
                    span {
                        font-weight: 500;
                        font-size: 1rem;
                        color: $gray-4;
                        white-space: nowrap;
                    }
                    button {
                        background-color: transparent;
                        border: none;
                        font-size: 1rem;
                        color: $gray-2;
                    }
                }
            }
        }
        /* 발송대상리스트 */
        .searchList {
            div {
                position: absolute;
                bottom: calc(100% + .5rem);
                left: 0;
                @include flexColumnContent;
                gap: .5rem;
                align-items: flex-end;
                width: 100%;
                button {
                    border: none;
                    background-color: transparent;
                    text-shadow: 0px 4px 16px 0px rgba(25, 26, 26, .80);
                    font-weight: 500;
                    font-size: .875rem;
                    color: $white;
                }
                ul {
                    width: calc(100% - 6.5rem - .75rem);
                    max-height: 430px;
                    @include comnBorderRadius;
                    background-color: $gray-0;
                    box-shadow: 0px 4px 16px 0px rgba(25, 26, 26, .80);
                    overflow-y: auto;
                    li {
                        // padding: 1.25rem;
                        padding: .62rem 1.25rem;
                        cursor: pointer;
                        span {
                            font-weight: 500;
                            // font-size: 1rem;
                            font-size: .875rem;
                            color: $gray-2;
                        }
                        &:not(:last-child) {
                            border-bottom: 1px solid $gray-2;
                        }
                        &:hover {
                            background-color: $white;
                            span {
                                color: $gray-0;
                            }
                        }
                    }
                }
            }
        }
        /* 발송대상리스트 */
    }
}
/* 알림샘플화면 */
.notifySampleArea {
    @include flexColumnContent;
    gap: .75rem;
    height: 648px;
    > div {
        position: relative;
        width: 100%;
        min-width: 495px;
        height: 50%;
        background: $gray-0;
        @include comnBorderRadius;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;
        > span {
            position: absolute;
            top: 1.25rem;
            left: 1.25rem;
            font-weight: 500;
            font-size: .875rem;
            color: $gray-25;
        }
        > article {
            position: absolute;
        }
        &:first-child {
            background-image: url('../../images/bg/notify-push-bg.webp');
            article {
                @include flexRowContent;
                gap: .75rem;
                bottom: .75rem;
                left: 50%;
                transform: translateX(-50%);
                box-sizing: border-box;
                width: 21.375rem;
                padding: .88rem 1.5rem;
                border-radius: 1.5rem;
                background-color: rgba(255, 255, 255, .6);
                > img {
                    width: 2.375rem;
                    height: 2.375rem;
                    @include comnBorderRadius;
                }
                > div {
                    @include flexColumnContent;
                    gap: 4px;
                    justify-content: flex-start;
                    width: calc(100% - 2.375rem);
                    > div {
                        @include flexBetweenContent;
                        gap: 1rem;
                        width: 100%;
                        label {
                            font-weight: 700;
                            font-size: .875rem;
                            color: $gray-0;
                            @include lineClamp;
                        }
                        span {
                            font-weight: 500;
                            font-size: .75rem;
                            color: rgba(127, 127, 127, .8);
                        }
                    }
                    pre {
                        width: 100%;
                        line-height: 120%;
                        font-weight: 500;
                        font-size: .875rem;
                        color: $gray-0;
                        white-space: nowrap;
                        @include lineClamp2;
                    }
                }
            }
        }
        &:nth-child(2) {
            background-image: url('../../images/bg/notify-onApp-bg.webp');
            > article {
                @include flexColumnContent;
                gap: 1rem;
                justify-content: flex-start;
                align-items: flex-start;
                bottom: 1.25rem;
                left: 50%;
                transform: translateX(-50%);
                width: 19.9rem;
                height: 6rem;
                > div {
                    @include flexRowContent;
                    gap: 1rem;
                    justify-content: flex-start;
                    width: 100%;
                    white-space: nowrap;
                    label {
                        font-weight: 500;
                        font-size: 1rem;
                        color: $gray-0;
                        @include lineClamp;
                    }
                    span {
                        font-weight: 400;
                        font-size: .875rem;
                        color: $gray-25;
                    }
                }
                pre {
                    width: 100%;
                    line-height: 150%;
                    font-weight: 500;
                    font-size: .875rem;
                    color: $gray-25;
                    white-space: pre-wrap;
                    @include lineClamp3;
                }
            }
        }
    }
}

input[type=text] {
    &::placeholder {
        color: $gray-2;
    }
}