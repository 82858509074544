@import '../config/variables';

/* 업체리스트 */
.listArea {
    ul {
        @include flexColumnContent;
        gap: .75rem;
        li {
            @include flexColumnContent;
            width: 100%;
            /* 컬럼간격조절공통 */
            .stretch {
                flex-grow: 1;
            }
            > div {
                @include flexRowContent;
                gap: 0;
                width: 100%;
                padding: 1rem 1.25rem;
                @include comnBorderRadius;
                background-color: $gray-0;
                >  div {
                    @include flexColumnContent;
                    gap: .12rem;
                    align-items: flex-start;
                    > * {
                        height: 1.5rem;
                        line-height: 150%;
                    }
                    * {
                        width: 100%;
                        font-weight: 500;
                        @include lineClamp;
                    }
                    label {
                        font-size: 1rem;
                        color: $white;
                        &.addr {
                            height: auto !important;
                        }
                    }
                    span {
                        font-size: .875rem;
                        color: $gray-25;
                    }
                    em {
                        font-size: .875rem;
                        color: $white;
                    }
                    h5 {
                        text-align: left;
                        font-size: 1rem;
                        &.await {
                            color: $gray-2;
                        }
                        &.reject {
                            color: $error;
                        }
                    }
                    button {
                        background-color: transparent;
                        border: none;
                        font-size: 1.25rem;
                        color: $white;
                    }
                    h2 {
                        @include flexRowContent;
                        gap: 4px;
                        justify-content: flex-start;
                        height: auto !important;
                        font-size: .875rem;
                        color: $gray-25;
                        input[type=text],
                        input[type=date] {
                            width: 8.925rem;
                            padding: .25rem 1rem;
                            border: 1px solid $gray-1;
                            @include comnBorderRadius;
                            background-color: $gray-1;
                            text-align: center;
                            color: $gray-25;
                        }
                        input[type=date] {
                            color-scheme: dark; 
                        }
                    }
                }
                input[type=checkbox] {
                    margin-right: 1.25rem;
                }
                hr {
                    display: block;
                    width: 1px;
                    height: 2.5rem;
                    padding: 0;
                    margin: 0 1.25rem;
                    background: $gray-1;
                    border: none;
                }
                .imgArea {
                    width: 2.75rem;
                    height: 2.75rem;
                    margin-right: 1.25rem;
                    @include roundedImgArea;
                }
            }
            > article {
                display: none;
            }
            /* 이미삭제된경우 */
            &.hidden {
                display: none !important;
            }
            /* 상세화면열려있을경우 */
            &.open {
                > div {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                article {
                    @include flexRowContent;
                    gap: 1.25rem;
                    justify-content: flex-start;
                    width: 100%;
                    @include comnBorderRadius;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    padding: 2.5rem 4rem;
                    background-color: $gray-0;
                    border-top: 1px solid $gray-1;
                    order: 4;
                    flex-grow: 1;
                    > div {
                        @include flexColumnContent;
                        gap: .5rem;
                        align-items: flex-start;
                        .imgArea {
                            width: 14rem;
                            height: 14rem;
                            @include comnBorderRadius;
                            overflow: hidden;
                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        label {
                            font-weight: 500;
                            font-size: .875rem;
                            color: $gray-4;
                        }
                    }
                    .btnArea {
                        @include flexColumnContent;
                        gap: .5rem;
                        button {
                            padding: .5rem;
                            @include comnBorderRadius;
                            background-color: transparent;
                            font-weight: 500;
                            font-size: .875rem;
                            &.submitButton {
                                border: 1px solid $gray-6;
                                color: $white;
                            }
                            &.rejectButton {
                                border: 1px solid $error;
                                color: $error;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* 이미지팝업 */
.popupImgContainer {
    .imgArea {
        height: 30rem;
        img {
            object-fit: cover;
        }
    }
}