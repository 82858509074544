@import '../config/variables';

/* 회원기본정보 */
.basicInfo {
    @include flexColumnContent;
    gap: 1rem;
    > * {
        width: 100%;
    }
    > div {
        @include flexRowContent;
        justify-content: flex-end;
    }
    ul {
        @include comnArrayList;
        gap: .75rem;
        li {
            > * {
                width: 100%;
            }
            hr {
                height: 1.25rem;
            }
            .regDate {
                @include flexRowContent;
                gap: .2rem;
                justify-content: flex-end;
                width: auto;
            }
        }
    }
}

.memberFlickPanel {
    @include flexColumnContent;
    justify-content: flex-start;
    gap: 4.5rem;
    width: 100%;
    > * {
        width: 100%;
        > .titleArea {
            div {
                margin-top: 0 !important;
                button {
                    font-size: 1rem;
                }
            }
        }
    }
}

/* 보유차량리스트*/
.vehicleListArea {
    width: 100%;
    ul {
        @include flexRowContent;
        gap: 1rem;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        li {
            @include flexColumnContent;
            box-sizing: content-box;
            min-width: 22.0625rem;
            width: 22.0625rem;
            padding: 1.25rem;
            background-color: $gray-0;
            @include comnBorderRadius;
            > * {
                width: 100%;
            }
            label, span, strong {
                @include lineClamp;
            }
            hr {
                width: 100%;
                height: 1px;
                margin: 1rem 0;
                padding: 0;
                border: none;
                background-color: $gray-1;
            }
            .vehicleInfo {
                @include flexColumnContent;
                gap: .5rem;
                width: 100%;
                div {
                    @include flexBetweenContent;
                    width: 100%;
                    label {
                        width: 100%;
                        font-weight: 500;
                        font-size: 1rem;
                        color: $white;
                    }
                    span {
                        font-weight: 500;
                        font-size: .875rem;
                        color: $gray-4;
                    }
                }
            }
            .vehicleLogArea {
                @include flexColumnContent;
                gap: .5rem;
                align-items: flex-start;
                color: $gray-25;
                > div {
                    @include flexBetweenContent;
                    width: 100%;
                    > div {
                        @include flexRowContent;
                        gap: .25rem;
                    }
                }
                .vehicleLog {
                    label {
                        &:last-child {
                            @include flexRowContent;
                            justify-content: flex-end;
                        }
                    }
                    button {
                        background-color: transparent;
                        border: none;
                        font-size: 1rem;
                        > * {
                            color: $gray-2 !important;
                        }
                    }
                }
                svg {
                    margin-right: .25rem;
                    font-size: 1rem;
                    color: $point-blue;
                }
                label {
                    width: 100%;
                    font-weight: 500;
                    font-size: .875rem;
                    white-space: nowrap;
                }
                strong {
                    margin-left: .25rem;
                    font-weight: 700;
                }
                span {
                    font-weight: 500;
                    font-size: .75rem;
                }
            }
        }
    }
}

/* 예약리스트 */
.reservContainer {
    height: 70%;
    ul {
        @include comnArrayToggleList;
        gap: .75rem;
        li {
            hr {
                height: 1.25rem;
            }
            article {
                .toggleContent {
                    padding: 1.25rem;
                    @include flexRowContent;
                    align-items: flex-start;
                    height: 100%;
                    > div > label {
                        margin-bottom: .75rem;
                    }
                    div {
                        @include flexColumnContent;
                        gap: .5rem;
                        align-items: flex-start;
                        width: 100%;
                        font-weight: 500;
                        > div {
                            @include flexColumnContent;
                            gap: .25rem;
                            align-items: flex-start;
                        }
                        span {
                            color: $gray-25;
                            strong {
                                margin-left: .25rem;
                                font-weight: 700;
                            }
                            svg {
                                margin-right: .25rem;
                                color: $point-blue;
                            }
                        }
                        label, pre {
                            color: $gray-4;
                        }
                        label, span {
                            font-size: .875rem;
                        }
                        pre {
                            line-height: 150%;
                            font-size: .75rem;
                        }
                    }
                    hr {
                        width: 1px;
                        height: 100% !important;
                        min-height: 9.25rem;
                        border: none;
                        background-color: $gray-1;
                        margin: 0 1.25rem;
                    }
                }
                .toggleContainer ul {
                    @include comnArrayList;
                    li {
                        background-color: $gray-15 !important;
                        div {
                            padding: 0 !important;
                        }
                        svg {
                            margin-right: .25rem;
                            font-size: 1rem;
                        }
                        .btnArea {
                            @include flexRowContent;
                            gap: .5rem;
                            width: auto !important;
                            white-space: nowrap;
                            button {
                                @include btnError;
                                height: auto !important;
                                padding: .38rem .5rem !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* START 주행거리수정 */
.popupFormArea {
    width: 100%;
}
.popupVehicleUpdate {
    @include flexColumnContent;
    align-items: flex-start;
    margin-bottom: .75rem;
    > * {
        line-height: 150%;
    }
    label {
        font-weight: 500;
        font-size: 1rem;
        color: $gray-25;
    }
    span {
        font-weight: 500;
        font-size: 1rem;
        color: $gray-1;
    }
}
/* END 주행거리수정 */
/* START 결제취소 */
.popupPaymentCancel {
    @include flexColumnContent;
    align-items: flex-start;
    margin-bottom: .75rem;
    > * {
        line-height: 150%;
    }
    label {
        font-weight: 500;
        font-size: 1rem;
        color: $gray-25;
    }
    span {
        font-weight: 500;
        font-size: 1rem;
        color: $gray-1;
    }
}
/* END 결제취소 */