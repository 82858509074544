@import url('https://rsms.me/inter/inter.css');
@import '../config/variables';

/**
 * 공통
 */
.select-search-container {
    box-sizing: border-box;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
    box-sizing: inherit;
}
.select-search-container * {
    cursor: pointer;
}

/* ===== Start of 검색필터 ===== */
/* 선택된옵션 */
.selectArea .select-search-value {
    position: relative;
    box-sizing: border-box;
    width: 8.2rem;
    height: 3rem;
    padding: .5rem .75rem;
    background-color: transparent;
    border: 1px solid $gray-4 !important;
    @include comnBorderRadius;
    font-weight: 500;
    font-size: 1rem;
    color: $gray-4;
    &::after {
        content: "\f078";
        font-family: "Font Awesome 6 Pro";
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        font-weight: 300;
        font-size: 1rem;
        color: $gray-4;
    }
}

.selectArea .select-search-container {
    width: 100%;
    position: relative;
    &:not(.select-search-is-disabled).select-search-has-focus {
        .select-search-value {
            &::after {
                content: "\f077";
                
            }
        }
    }
    &:not(.select-search-is-multiple).select-search-has-focus {
        .select-search-select {
            display: block;
        }
    }
}

.selectArea .select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    border: none !important;
    outline: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    background-color: transparent;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
    color: $gray-4;
}

/* 옵션리스트 */
.selectArea .select-search-select {
    background: $gray-1;
    box-shadow: 0px 4px 16px 0px rgba(25, 26, 26, .80);
    max-height: 300px;
    overflow: auto;
}
.selectArea .select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    top: calc(100% + 8px);
    right: 0;
    left: 0;
    display: none;
    border: 1px solid $gray-2;
    @include comnBorderRadius;
}

.selectArea .select-search-option,
.selectArea .select-search-not-found {
    display: block;
    width: 100%;
    padding: .62rem .75rem;
    background: $gray-1;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: .875rem;
    color: $gray-2;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}
.selectArea .select-search-options {
    li {
        &:not(:last-child) {
            border-bottom: 1px solid $gray-2;
        }
    }
}
/* 옵션리스트내 선택아이템 */
.selectArea .select-search-is-selected {
    background: $white;
    color: $gray-0;

}
/* ===== End of 검색필터 ===== */

/* ===== Start of inside리스트 ===== */
/* 선택된옵션 */
.listAreaSelectFilter {
    position: relative;
}
.listAreaSelectFilter .select-search-value {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 1.25rem;
    padding: 0;
    background-color: transparent;
    border: none !important;
    @include comnBorderRadius;
    font-weight: 500;
    font-size: .875rem;
    color: $gray-4;
    &::after {
        content: "\f078";
        font-family: "Font Awesome 6 Pro";
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        font-weight: 300;
        font-size: 1rem;
        color: $gray-4;
    }
}

.listAreaSelectFilter .select-search-container {
    &:not(.select-search-is-disabled).select-search-has-focus {
        .select-search-value {
            &::after {
                content: "\f077";
                
            }
        }
    }
    &:not(.select-search-is-multiple).select-search-has-focus {
        .select-search-select {
            display: block;
        }
    }
}

.listAreaSelectFilter .select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    max-width: 6.5rem;
    height: 100%;
    padding: 0 !important;
    border: none !important;
    outline: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    background-color: transparent;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 500;
    font-size: .875rem;
    color: $gray-25;
}

/* 옵션리스트 */
.listAreaSelectFilter .select-search-select {
    background: $gray-2;
    box-shadow: 0px 4px 16px 0px rgba(25, 26, 26, .80);
    max-height: 300px;
    overflow: auto;
    ul {
        gap: 0;
        li {
            padding: 0;
            border-radius: 0 !important;
            button {
                border-radius: 0 !important;
            }
        }
    }
}
.listAreaSelectFilter .select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    display: none;
    z-index: 2;
    top: calc(1.25rem + 8px);
    right: 0;
    left: 0;
    width: 6.5rem;
    border: 1px solid $gray-2;
    @include comnBorderRadius;
}

.listAreaSelectFilter .select-search-option,
.listAreaSelectFilter .select-search-not-found {
    display: block;
    width: 100%;
    padding: .62rem .75rem;
    background: $gray-1;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: .875rem;
    color: $gray-2;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}
.listAreaSelectFilter .select-search-options {
    li {
        &:not(:last-child) {
            border-bottom: 1px solid $gray-2;
        }
    }
}
/* 옵션리스트내 선택아이템 */
.listAreaSelectFilter .select-search-is-selected {
    background: $white;
    color: $gray-0;

}
/* ===== End of inside리스트 ===== */

/* ===== Start of 입력폼 ===== */
/* 선택된옵션 */
.formSelectArea .select-search-value {
    position: relative;
    box-sizing: border-box;
    width: 6.5rem;
    padding: 1.25rem .75rem;
    background-color: $gray-0;
    border: 1px solid $gray-0 !important;
    @include comnBorderRadius;
    font-weight: 500;
    font-size: 1rem;
    color: $gray-2;
    &::after {
        content: "\f078";
        font-family: "Font Awesome 6 Pro";
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        font-weight: 300;
        font-size: 1rem;
        color: $white;
    }
}

.formSelectArea .select-search-container {
    width: 100%;
    position: relative;
    &:not(.select-search-is-disabled).select-search-has-focus {
        .select-search-value {
            &::after {
                content: "\f077";
                
            }
        }
    }
    &:not(.select-search-is-multiple).select-search-has-focus {
        .select-search-select {
            display: block;
        }
    }
}

.formSelectArea .select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    border: none !important;
    outline: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    background-color: transparent;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
    color: $gray-4;
}

/* 옵션리스트 */
.formSelectArea .select-search-select {
    background: $gray-1;
    box-shadow: 0px 4px 16px 0px rgba(25, 26, 26, .80) !important;
    max-height: 300px;
    overflow: auto !important;
}
.formSelectArea {
    .select-search-container:not(.select-search-is-multiple) .select-search-select {
        position: absolute;
        z-index: 2;
        right: 0;
        left: 0;
        display: none;
        border: 1px solid $gray-1;
        @include comnBorderRadius;
        overflow: auto !important;
    }
    &:not(.upSelectOption) {
        .select-search-container:not(.select-search-is-multiple) .select-search-select {
            top: calc(100% + 8px) !important;
        }
    }
    &.upSelectOption {
        .select-search-container:not(.select-search-is-multiple) .select-search-select {
            bottom: calc(100% + 8px) !important;
        }
    }
}

.formSelectArea .select-search-option,
.formSelectArea .select-search-not-found {
    display: block;
    width: 100%;
    padding: .62rem .75rem;
    background: $gray-0;
    border: none;
    border-radius: 0 !important;
    outline: none;
    font-weight: 500;
    font-size: .875rem;
    color: $gray-2;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    &:disabled {
        background: $gray-1;
    }
}
.formSelectArea .select-search-options {
    li {
        &:not(:last-child) {
            border-bottom: 1px solid $gray-2;
        }
    }
}
/* 옵션리스트내 선택아이템 */
.formSelectArea .select-search-is-selected {
    background: $white;
    color: $gray-0;

}
/* ===== End of 입력폼 ===== */
/* START light 셀렉트 */
.themeLight.formSelectArea {
    .select-search-value {
        border: 1px solid $gray-4 !important;
        background-color: transparent !important;
        color: $gray-0 !important;
        &::after {
            color: $gray-0 !important;
        }
    }
    .select-search-input {
        color: $gray-0 !important;
    }
    .select-search-container:not(.select-search-is-multiple) .select-search-select {
        border: 1px solid $gray-5 !important;
    }
    .select-search-options li:not(:last-child) {
        border-bottom: 1px solid $gray-5 !important;
    }
    .select-search-select {
        background-color:  $white !important;
        box-shadow: 0px 4px 16px 0px rgba(25, 26, 26, .14) !important;
        .select-search-option {
            background-color: $gray-6 !important;
            &:disabled {
                background-color: $gray-45 !important;
                color: $gray-5 !important;
            }
        }
        .select-search-is-selected {
            background-color: $white !important;
        }
    }
}
/* END light 셀렉트 */
/* START 팝업셀렉트 */
.popupFormArea {
    .select-search-value {
        padding: 1.25rem;
    }
    .select-search-option, .select-search-not-found {
        padding: .75rem 1.25rem;
    }
}

/* END 팝업셀렉트 */