@import '../config/variables';

button.submitButton {
    @include submitButton;
    width: 100%;
    border: 1px solid $primary;
    background: $primary;
    span {
        display: block;
        box-sizing: border-box;
        padding: 1.19rem;
        font-weight: 600;
        font-size: 1.125rem;
        color: $gray-0;
    }
    &:disabled {
        border: 1px solid $gray-4;
        background-color: $gray-4;
        span {
            color: $white;
        }
    }
}

.subtitleButton {
    @include flexRowContent;
    gap: .25rem;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    color: $gray-25;
    svg {
        font-size: 1rem;
        color: $gray-25 !important;
    }
}

/* START 팝업버튼 */
.popupCloseButton {
    @include flexRowContent;
    gap: .75rem;
    min-width: 4.8rem;
    max-width: 4.8rem;
    padding: 1.19rem .5rem;
    border: none;
    background: transparent;
    span {
        color: $gray-25;
    }
    svg {
        font-size: 1.25rem;
    }
}
.popupSubmitButton {
    background-color: $primary;
    border: 1px solid $primary;
    &:disabled {
        border: 1px solid $gray-4;
        background-color: $gray-4;
        span {
            color: $white;
        }
    }
}
/* END 팝업버튼 */