@import '../config/variables';

.listArea {
    ul {
        @include comnArrayList;
        gap: .75rem;
        width: 100%;
        li {
            hr {
                height: 1.25rem;
            }
            h5 {
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }
}