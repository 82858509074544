@import '../config/variables';

/* 제조사리스트 */
.brandListArea {
    width: 100%;
    padding: 1.25rem;
    background: $gray-0;
    @include comnBorderRadius;
    ul {
        @include flexRowContent;
        row-gap: 1rem;
        justify-content: flex-start;
        flex-flow: row wrap;
        width: 100%;
        max-height: 11.5rem;
        overflow-y: auto;
        li {
            @include flexColumnContent;
            gap: .5rem;
            width: calc(100%/11);
            cursor: pointer;
            .imgArea {
                @include flexRowContent;
                width: 4rem;
                height: 4rem;
                @include roundedImgArea;
                background-color: $gray-6;
                img {
                    width: 2.75rem;
                    height: 2.75rem;
                    object-fit: contain;
                }
            }
            span {
                display: inline-block;
                width: 84px;
                text-align: center;
                font-weight: 500;
                font-size: .75rem;
                color: $white;
                @include lineClamp;
            }
        }
    }
}

/* 제조사아이템리스트 */
.brandItemListArea {
    height: 100%;
    ul {
        @include comnArrayList;
        gap: .75rem;
        li {
            em {
                font-size: .875rem;
                color: $white;
            }
            &.toggleArea {
                @include flexRowContent;
                margin-left: 1.5rem;
                label {
                    @include flexRowContent;
                }
            }
            button {
                @include flexRowContent;
                gap: .25rem;
                padding: 0.375rem 0.5rem;
                background-color: transparent;
                border: none;
                @include comnBorderRadius;
                color: $white;
                font-size: 1rem;
                svg {
                    font-size: 1.25rem;
                    color: $primary;
                }
            }
            .imgArea {
                width: 2.75rem;
                height: 2.75rem;
                margin-right: 1.25rem;
                @include comnBorderRadius;
            }
            hr {
                height: 1.25rem;
            }
        }
    }
}

/* modalPopup */
.stepperArea {
    @include flexBetweenContent;
    border-radius: .5rem;
    background: $gray-6;
    padding: .88rem 2.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: $gray-25;
    span {
        font-weight: 500;
        font-size: .875rem;
        &.selected {
            font-weight: 700;
            color: $primary-outline;
        }
    }
    svg {
        font-size: 1rem;
    }
}
/* modalPopup 브랜드선택 */
.popupBrandListArea {
    @include flexColumnContent;
    > * {
        width: 100%;
    }
    ul {
        @include flexBetweenContent;
        column-gap: .75rem;
        row-gap: .75rem;
        flex-flow: row wrap;
        li {
            @include flexColumnContent;
            gap: .5rem;
            cursor: pointer;
            
            .imgArea {
                @include flexRowContent;
                width: 4rem;
                height: 4rem;
                background-color: $gray-6;
                @include roundedImgArea;
                img {
                    width: 2.75rem;
                    height: 2.75rem;
                    object-fit: contain;
                }
            }
            span {
                width: 4rem;
                text-align: center;
                font-weight: 500;
                font-size: .75rem;
                color: $gray-1;
                @include lineClamp;
            }
            &.selected {
                .imgArea {
                    background-color: $gray-0 !important;
                }
            }
        }
    }
}