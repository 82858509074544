@import '../config/variables';

.boardListContainer {
    ul {
        @include arrayListElement;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 1.25rem;
        row-gap: .775rem;
        width: 100%;
        li {
            width: calc(50% - (1.25rem/2));
            @include comnBorderRadius;
            overflow: hidden;
            > * {
                background-color: $gray-0;
                padding: 1.25rem;
            }
            > div {
                @include flexColumnContent;
                gap: .75rem;
                width: 100%;
                @include comnBorderRadius;
                &.selected {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                > * {
                    width: 100%;
                }
                .title {
                    @include flexBetweenContent;
                    .btnArea {
                        @include flexRowContent;
                        gap: 1rem;
                    }
                }
                .info {
                    @include flexRowContent;
                    justify-content: flex-start;
                }
            }
            article {
                border-top: 1px solid $gray-1;
                line-height: 150%;
                font-weight: 500;
                font-size: .875rem;
                color: $gray-4;
            }
            hr {
                height: 1.25rem;
            }
        }
    }
}