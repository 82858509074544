@import '../config/variables';

/* 제조사리스트 */
.brandListArea {
    height: 100%;
    ul {
        @include flexRowContent;
        gap: .75rem;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0;
            width: calc(25% - ((.75rem * 3)/4));
            @include comnBorderRadius;
            background-color: $gray-0;
            padding: 1.25rem;
            box-sizing: border-box;
            > input[type=checkbox] {
                margin-right: .62rem;
            }
            >  div {
                @include flexColumnContent;
                align-items: flex-start;
                > * {
                    line-height: 150%;
                }
                * {
                    width: 100%;
                    font-weight: 500;
                }
                label {
                    width: 100%;
                    font-size: 1rem;
                    color: $white;
                    word-break:break-all;
                }
                &.toggleArea {
                    @include flexRowContent;
                    label {
                        @include flexRowContent;
                    }
                }
                button {
                    @include flexRowContent;
                    background-color: transparent;
                    border: none;
                    @include comnBorderRadius;
                    svg {
                        font-size: 1.25rem;
                        color: $primary;
                    }
                }
            }
            .stretch {
                flex-grow: 1;
                > * {
                    text-align: left;
                }
            }
            .imgArea {
                @include flexRowContent;
                width: 2.75rem;
                height: 2.75rem;
                margin-right: .62rem;
                background-color: $gray-6;
                @include roundedImgArea;
                img {
                    width: 2.125rem;
                    height: 2.125rem;
                    object-fit: contain;
                }
            }
        }
    }
}