@import '../config/variables';

.emptyArea {
    @include flexColumnContent;
    gap: 4px;
    width: 100%;
    height: 100%;
    @include comnBorderRadius;
    background-color: $gray-0;
    svg {
        margin-bottom: 2rem;
        font-size: 4.8rem;
        color: $gray-25;
    }
    h5 {
        font-weight: 700;
        font-size: 1.5rem;
        color: $white;
    }
    p {
        font-weight: 500;
        font-size: 1rem;
        color: $gray-4;
    }
    &.small {
        svg {
            font-size: 2.5rem;
        }
        h5 {
            font-size: 1.25rem;
        }
        p {
            font-size: .875rem;
        }
    }
}