@import '../config/variables';

h1 {
    font-weight: 600;
    font-size: 1.125rem;
}

/* START 브랜드선택 */
.brandContainer {
    @include flexColumnContent;
    gap: .75rem;
    > * {
        width: 100%;
    }
    .brandListArea {
        padding: 1.25rem;
        background-color: $gray-0;
        @include comnBorderRadius;
        width: 100%;
        .listArea {
            height: 11.5rem;
            max-height: 11.5rem;
            overflow-y: auto;
            > * {
                width: 100%;
            }
            ul {
                @include flexRowContent;
                row-gap: 1rem;
                justify-content: flex-start;
                flex-flow: row wrap;
                width: 100%;
                li {
                    @include flexColumnContent;
                    gap: .5rem;
                    width: calc(100%/11);
                    cursor: pointer;
                    .imgArea {
                        @include flexRowContent;
                        width: 4rem;
                        height: 4rem;
                        @include roundedImgArea;
                        background-color: $gray-6;
                        img {
                            width: 2.75rem;
                            height: 2.75rem;
                            object-fit: contain;
                        }
                    }
                    span {
                        display: inline-block;
                        width: 84px;
                        text-align: center;
                        font-weight: 500;
                        font-size: .75rem;
                        color: $white;
                        @include lineClamp;
                    }
                }
            }
        }
    }
}
/* END 브랜드선택 */
/* START 모델선택 */
.modelContainer {
    @include gridContent;
    grid-template-rows: auto 1fr calc((.38rem * 2) + 2.25rem + 2px);
    justify-content: normal;
    row-gap: .5rem;
    height: 100%;
    > * {
        width: 100%;
    }
    h2 {
        font-weight: 500;
        font-size: 1rem;
        color: $gray-2;
    }
    .modelListArea {
        flex-grow: 1;
        height: calc(100% - ((.38rem * 2) + 2.25rem));
        max-height: calc(100% - ((.38rem * 2) + 2.25rem));
        overflow-y: auto;
        ul {
            @include gridContent;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1.25rem;
            row-gap: 1.25rem;
                        
            li {
                @include flexRowContent;
                justify-content: flex-start;
                gap: .5rem;
                span {
                    font-weight: 500;
                    font-size: 1rem;
                    color: $gray-25;
                }
            }
        }
    }
}
/* END 모델선택 */
/* START 버튼 */
.btnArea {
    @include flexRowContent;
    justify-content: flex-start;
    gap: .5rem;
    a,
    button {
        @include flexRowContent;
        gap: .5rem;
        box-sizing: content-box;
        height: 2.25rem;
        padding: .38rem .75rem;
        background-color: transparent;
        border: 1px solid $white;
        @include comnBorderRadius;
        color: $white;
        span {
            font-size: .875rem;
        }
        &:disabled {
            color: $gray-2;
            border: 1px solid $gray-2;
        }
    }
}
/* END 버튼 */