@import '../config/variables';

.formContainer {
    @include gridContent;
    grid-template-rows: 1fr calc((.38rem * 2) + 2.25rem + 2px);
    gap: 2.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    > * {
        width: 100%;
    }
}
.formArea {
    @include flexColumnContent;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.25rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    > div {
        @include flexColumnContent;
        align-items: flex-start;
        gap: .75rem;
        width: 100%;
        label {
            line-height: 125%;
            font-weight: 500;
            font-size: .875rem;
            color: $white;
        }
        input[type=text] {
            width: 35.125rem;
            padding: 1.25rem;
            border: none;
            @include comnBorderRadius;
            background-color: $gray-0;
            line-height: 150%;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-4;
        }
        ul.vehicleListArea {
            @include flexRowContent;
            justify-content: flex-start;
            gap: 1.25rem;
            width: 100%;
            overflow-x: auto;
            li {
                @include flexRowContent;
                gap: .5rems;
                height: 3rem;
                white-space: nowrap;
                label {
                    line-height: 150%;
                    font-weight: 500;
                    font-size: 1rem;
                    color: $gray-4;
                }
                button {
                    border: none;
                    background-color: transparent;
                    font-size: 1rem;
                    color: $gray-2;
                }
            }
        }
        button {
            @include flexRowContent;
            gap: .5rem;
            background-color: transparent;
            border: 1px solid $white;
            @include comnBorderRadius;
            padding: .88rem .75rem;
            > * {
                color: $white;
            }
            svg {
                font-size: 1rem;
            }
            span {
                font-size: .875rem;
            }
        }
    }
    /* inputFile */
    .fileArea {
        position: relative;
        label {
            @include flexRowContent;
            gap: .5rem;
            width: 14rem;
            height: 14rem;
            padding: 1.25rem;
            box-sizing: border-box;
            border: 1px dashed $gray-2;
            @include comnBorderRadius;
            background-color: transparent;
            font-weight: 600;
            font-size: 1.125rem;
            color: $gray-2;
            cursor: pointer;
            svg {
                font-size: 1rem;
            }
        }
        input[type="file"] {
            position: absolute;
            width: 0;
            height: 0;
            padding: 0;
            overflow: hidden;
            border: 0;
        }
    }
    /* 이미지미리보기 */
    .imgPreview {
        @include flexColumnContent;
        gap: 1rem;
        width: 14rem;
        box-sizing: border-box;
        button {
            @include flexRowContent;
            gap: .25rem;
            width: 100%;
            padding: .62rem .75rem;
            border: 1px solid $white;
            @include comnBorderRadius;
            background-color: transparent;
            > * {
                color: $white;
            }
            svg {
                font-size: 1rem;
            }
            span {
                line-height: 125%;
                font-size: .875rem;
            }
        }
        .previewImage {
            width: 100%;
            height: 14rem;
            background-color: $gray-6;
            @include comnBorderRadius;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
/* START 라디오버튼 */
.radioArea {
    @include flexRowContent;
    gap: 1.25rem;
    .radio {
        position: relative;
        @include flexRowContent;
        gap: 1rem;
        padding: 1.25rem;
        background: $gray-0;
        @include comnBorderRadius;
        line-height: 150%;
        label {
            font-weight: 500;
            font-size: 1rem;
            color: $white;
        }
    }
}
/* END 라디오버튼 */
/* START 라디오버튼 */
.formSelectArea {
    > div > div {
        width: 35.125rem !important;
    }
    
}
/* END 라디오버튼 */
/* START 사용차량팝업 */
.popupFormArea {
    /* 셀렉트커스텀 */
    .formSelectArea {
        width: 100%;
        @include flexColumnContent;
        gap: .75rem;
        * {
            width: 100% !important;
        }
    }
    .radioArea {
        @include flexColumnContent;
        gap: .75rem;
        width: 100%;
        .radioWrap {
            @include gridContent;
            grid-template-columns: auto 1fr;
            gap: 1rem;
            width: 100%;
            padding: 1.25rem;
            background-color: $gray-6;
            @include comnBorderRadius;
            label {
                font-weight: 500;
                font-size: 1rem;
                color: $gray-25;
                @include lineClamp;
            }
            &.selected {
                label {
                    color: $gray-0;
                }
            }
        }
    }
}
/* END 사용차량팝업 */
/* START 버튼 */
.btnArea {
    @include flexRowContent;
    justify-content: flex-start;
    gap: .5rem;
    a,
    button {
        @include flexRowContent;
        gap: .5rem;
        box-sizing: content-box;
        height: 2.25rem;
        padding: .38rem .75rem;
        background-color: transparent;
        border: 1px solid $white;
        @include comnBorderRadius;
        color: $white;
        span {
            font-size: .875rem;
        }
        &:disabled {
            color: $gray-2;
            border: 1px solid $gray-2;
        }
    }
}
/* END 버튼 */