@import '../config/variables';

header {
    @include flexColumnContent;
    align-items: flex-start;
    box-sizing: border-box;
    width: 18rem;
    height: 100%;
    padding: 3.5rem 2rem;
    background: $gray-0;
    .userInfo {
        @include flexRowContent;
        gap: .75rem;
        width: 100%;
        img {
            width: 2rem;
            height: 2rem;
        }
        span {
            display: inline-block;
            width: 100%;
            line-height: 120%;
            font-weight: 600;
            font-size: 1.25rem;
            color: $white;
            @include lineClamp;
            em {
                font-size: .875rem;
            }
        }
    }
    nav {
        flex-grow: 1;
        margin-top: 5rem;
        > ul {
            @include flexColumnContent;
            gap: .5rem;
            > li {
                button,
                a {
                    box-sizing: border-box;
                    @include flexRowContent;
                    gap: 1.25rem;
                    justify-content: flex-start;
                    cursor: pointer;
                    span {
                        font-weight: 500;
                        font-size: 1rem;
                        color: $white;
                    }
                }
                > button {
                    width: 100%;
                    padding: 1rem 1.25rem;
                    border: 1px solid $gray-0;
                    svg {
                        display: block;
                        width: 1.5rem;
                        height: 1.5rem;
                        text-align: left;
                        font-size: 1.25rem;
                        color: $white;
                    }
                }
                &.selected {
                    > button {
                        border: 1px solid $primary;
                        @include comnBorderRadius;
                        svg {
                            color: $primary;
                        }
                        span {
                            color: $primary;
                        }
                    }
                }
                > ul {
                    @include flexColumnContent;
                    gap: 1.5rem;
                    position: relative;
                    height: 100%;
                    padding: 1rem 1.25rem;
                    background: rgba(55, 55, 55, .4);
                    @include comnBorderRadius;
                    li {
                        a {
                            border: none;
                        }
                        &.selected {
                            > a {
                                border: none;
                                span {
                                    color: $primary;
                                }
                            }
                        }
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 1rem;
                        left: 2rem;
                        width: 1px;
                        height: calc(100% - (1rem * 2) - .75rem);
                        background-color: $gray-5;
                    }
                }
            }
        }
    }
    nav,
    nav ul,
    nav ul li {
        width: 100%;
    }
    button {
        @include flexRowContent;
        gap: .25rem;
        border: none;
        background: none;
        padding: 0 .75rem;
        font-weight: 600;
        font-size: 1rem;
        color: $gray-25;
    }
}

.deptArrow {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 50%;
        height: 1px;
        background-color: $gray-5;
    }
}