@import '../config/variables';

/* 타이틀 */
.titleArea {
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
    padding-bottom: .75rem;
    border-bottom: 1px solid $gray-2;
    > div {
        @include flexBetweenContent;
        h1 {
            @include flexRowContent;
            font-weight: 600;
            font-size: 1.125rem;
            color: $white;
            em {
                margin-left: .5rem;
                font-weight: 500;
                font-size: .875rem;
                color: $primary;
            }
        }
        div {
            @include flexRowContent;
            gap: 1rem;
            a,
            button {
                @include flexRowContent;
                gap: .25rem;
                font-size: 1rem;
            }
        }
    }
}

/* 기본정보 */
.basicInfo {
    @include flexRowContent;
    gap: 2.5rem;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.5rem;
    background-color: $gray-0;
    .imgArea {
        width: 6.5rem;
        height: 6.5rem;
        min-width: 6.5rem;
        background-color: $gray-4;
        @include roundedImgArea;
    }
    .infoTextArea {
        @include flexColumnContent;
        gap: 1rem;
        width: 100%;
        > div {
            width: 100%;
            &:first-child {
                margin-bottom: -.5rem;
            }
            &.boxLine1 > div,
            &.boxLine2,
            &.boxLine3, 
            &.boxArea {
                @include flexRowContent;
                justify-content: flex-start;
            }
            &.boxLine1 {
                @include flexBetweenContent;
                width: 100%;
                button {
                    border: none;
                    background-color: transparent;
                    font-size: 1.5rem;
                    color: $primary;
                }
            }
            &.boxArea,
            &.boxLine2 {
                gap: .5rem;
                label {
                    font-weight: 600;
                    font-size: 1.25rem;
                    color: $white;
                }
                em {
                    font-weight: 500;
                    font-size: 1rem;
                    color: $gray-4;
                }
                div {
                    border-radius: .125rem;
                    padding: .12rem .25rem;
                    background-color: $gray-1;
                    font-weight: 500;
                    font-size: .75rem;
                    color: $gray-25;
                }
            }
            .primary {
                color: $primary;
            }
            hr {
                width: 1px;
                height: .75rem;
                margin: .5rem;
                border: none;
                background-color: $gray-1;
            }
            span {
                font-weight: 500;
                font-size: .875rem;
                color: $gray-25;
                svg {
                    margin-right: .5rem;
                    font-size: 1rem;
                }
            }
            pre {
                max-height: 50px;
                overflow-y: auto;
                line-height: 150%;
                font-weight: 500;
                font-size: .875rem;
                color: $gray-2;
                white-space: break-spaces;
            }
        }
    }
}
/* 이미지리스트 */
.imgContainer {
    margin-top: 2.5rem;
    .titleArea {
        margin-top: 0 !important;
    }
    ul {
        @include flexRowContent;
        gap: 1.12rem;
        justify-content: flex-start;
        li {
            @include flexColumnContent;
            gap: .5rem;
            align-items: flex-start;
            label {
                font-size: .875rem;
                font-weight: 500;
                color: $gray-4;
            }
            img {
                width: 14rem;
                height: 14rem;
                @include comnBorderRadius;
                object-fit: cover;
            }
            .fileArea {
                position: relative;
                @include flexRowContent;
                gap: .5rem;
                label {
                    padding: .38rem .5rem;
                    background: transparent;
                    @include comnBorderRadius;
                    font-size: .875rem;
                    font-weight: 500;
                    &.updateButton {
                        border: 1px solid $gray-7;
                        color: $white;
                    }
                    &.deleteButton {
                        border: 1px solid $error;
                        color: $error;
                    }
                }
                input[type="file"] {
                    position: absolute;
                    width: 0;
                    height: 0;
                    padding: 0;
                    overflow: hidden;
                    border: 0;
                }
            }
        }
    }
}
/* 취급차량리스트 */
.vehicleContainer {
    .vehicleListArea {
        @include flexColumnContent;
        gap: .75rem;
        ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: .75rem;
            width: 100%;
            overflow: auto;
            li {
                @include flexRowContent;
                gap: 1rem;
                box-sizing: content-box;
                height: 3.75rem;
                padding: 0 1.25rem;
                background-color: $gray-0;
                > * {
                    white-space: nowrap;
                }
                label {
                    font-weight: 500;
                    font-size: 1rem;
                    color: $white;
                }
                em {
                    font-weight: 500;
                    font-size: .875rem;
                    color: $error;
                }
                button {
                    background-color: transparent;
                    border: none;
                    font-size: 1rem;
                    color: $white;
                }
            }
        }
    }
}
/* 업체등록상품리스트 */
.itemContainer {
    .itemSearchwordArea {
        @include flexRowContent;
        gap: .5rem;
        padding: .75rem;
        background-color: $gray-0;
        @include comnBorderRadius;
        > * {
            font-size: 1rem;
            color: $white;
        }
        input[type=search] {
            background-color: transparent;
            border: none;
            font-weight: 500;
            
        }
    }
    .subtitleArea {
        margin-bottom: 1rem;
        > div {
            @include flexBetweenContent;
            h1 {
                @include flexRowContent;
                font-weight: 600;
                font-size: 1.125rem;
                color: $white;
                em {
                    margin-left: .5rem;
                    font-weight: 500;
                    font-size: .875rem;
                    color: $primary;
                }
            }
        }
    }
    .itemListArea {
        @include flexColumnContent;
        gap: 2.5rem;
        > div {
            width: 100%;
        }
    }
    ul {
        @include flexRowContent;
        justify-content: flex-start;
        gap: 1rem;
        width: 100%;
        overflow-x: auto;
        li {
            @include flexColumnContent;
            align-items: flex-start;
            gap: .5rem;
            width: 8.0625rem;
            .itemState {
                @include flexBetweenContent;
                width: 100%;
                span {
                    font-weight: 500;
                    font-size: .875rem;
                    color: $error;
                }
            }
            > div {
                @include flexColumnContent;
                align-items: flex-start;
                gap: .5rem;
                width: 100%;
            }
            .imgNoneArea, .imgArea {
                width: 8.0625rem;
                height: 8.0625rem;
                margin-top: .25rem;
                background-color: $white;
                @include comnBorderRadius;
                overflow: hidden;
            }
            .imgNoneArea {
                @include flexRowContent;
                span {
                    font-weight: 500;
                    font-size: .75rem;
                    color: $gray-2;
                }
            }
            > * {
                line-height: 120%;
                font-weight: 500;
            }
            h1 {
                width: 100%;
                font-size: .875rem;
                color: $gray-4;
                @include lineClamp;
            }
            h2 {
                width: 100%;
                font-size: .875rem;
                color: $gray-2;
                @include lineClamp;
            }
            h3 {
                font-size: 1rem;
                color: $primary-outline;
            }
        }
    }
}
/* 업체리뷰리스트 */
.reviewContainer {
    ul {
        @include comnArrayToggleList;
        gap: .75rem;
        li {
            .dateTime {
                span {
                    @include flexRowContent;
                    svg {
                        color: inherit;
                        margin-right: .25rem;
                    }
                }
            }
            h5 {
                text-align: left;
                font-size: 1rem;
                color: $error;
            }
            hr {
                height: 1.25rem;
            }
            .starRate {
                svg {
                    font-size: 1rem !important;
                    color: $primary !important;
                }
            }
            button {
                @include flexRowContent;
                gap: .12rem;
                padding: 0.375rem 0.5rem;
                background-color: transparent;
                border: 1px solid $error;
                white-space: nowrap;
                @include comnBorderRadius;
                > * {
                    color: $error;
                }
                span {
                    font-size: .875rem;
                    overflow: visible;
                }
            }
            a {
                width: 1rem;
                margin-left: 1.25rem;
                text-align: center;
                &.none {
                    > * {
                        color: transparent !important;
                    }
                }
            }
            article {
                padding: 1.25rem;
                border-top: 1px solid $gray-1;
                pre {
                    line-height: 150%;
                    font-weight: 500;
                    font-size: .875rem;
                    color: $gray-25;
                }
            }
        }
    }
}

/* 최소높이 */
.emptyData {
    width: 100% !important;
    height: 25rem;
    > * {
        width: 100%;
    }
}

/* START 파트너정보수정팝업 */
.popupFormArea {
    .imgArea {
        width: 8.25rem;
        height: 8.25rem;
        border-radius: 50%;
        overflow: hidden;
    }
    .formContainer {
        @include flexColumnContent;
        gap: 1.25rem;
        align-items: flex-start;
        width: 100%;
        margin-top: 1.25rem;
        label {
            font-weight: 500;
            font-size: .875rem;
            color: $gray-1;
        }
        .formArea {
            @include flexColumnContent;
            gap: .75rem;
            align-items: flex-start;
            width: 100%;
            > div {
                width: 100%;
                padding: 1.25rem;
                background-color: transparent;
                border: 1px solid $gray-4;
                @include comnBorderRadius;
                > * {
                    width: 100%;
                    border: none;
                    background-color: transparent;
                    line-height: 150%;
                    font-weight: 500;
                    font-size: 1rem;
                    color: $gray-1;
                }
            }
            &.isDisabled {
                > div {
                    border: 1px solid $gray-6;
                    background-color: $gray-6;
                }
            }
        }
        /* 셀렉트커스텀 */
        .formSelectArea {
            width: 100%;
            @include flexColumnContent;
            gap: .75rem;
            * {
                width: 100% !important;
            }
        }
    }
}
/* END 파트너정보수정팝업 */
/* START 파트너아이템복사하기 */
.infoContainer {
    @include flexColumnContent;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    .infoArea {
        @include flexBetweenContent;
        width: 100%;
        > * {
            white-space: nowrap;
        }
        label {
            @include lineClamp;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-25;
        }
        span {
            font-weight: 600;
            font-size: 1rem;
            color: $primary-outline;
        }
    }
}
.radioContainer {
    @include flexColumnContent;
    align-items: flex-start;
    gap: .75rem;
    width: 100%;
    .checkedArea {
        @include flexRowContent;
        gap: 1.25rem;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;
        li {
            > * {
                white-space: nowrap;
            }
            @include flexRowContent;
            gap: .5rem;
            label {
                line-height: 150%;
                font-weight: 500;
                font-size: 1rem;
                color: $gray-1;
            }
            svg {
                font-size: 1rem;
                color: $gray-2;
            }
        }
    }
    .searchArea {
        @include gridContent;
        grid-template-columns: 1fr auto;
        gap: .5rem;
        border: 1px solid $gray-2;
        width: 100%;
        padding: .75rem 1.25rem;
        @include comnBorderRadius;
        input[type=text] {
            border: none;
            line-height: 150%;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-1;
        }
        svg {
            font-size: 1rem;
            color: $gray-0;
        }
    }
}
.radioArea {
    @include flexColumnContent;
    gap: .75rem;
    width: 100%;
    .radioWrap {
        @include gridContent;
        grid-template-columns: auto 1fr;
        gap: 1rem;
        width: 100%;
        padding: 1.25rem;
        background-color: $gray-6;
        @include comnBorderRadius;
        label {
            font-weight: 500;
            font-size: 1rem;
            color: $gray-25;
        }
        &.selected {
            label {
                color: $gray-0;
            }
        }
    }
}
/* END 파트너아이템복사하기 */